import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "@reach/router"

const imageQuery = graphql`
  query getImagesQuery {
    allContentfulPainting {
      edges {
        node {
          image {
            file {
              url
              fileName
            }
          }
          name
          description {
            description
          }
          year
        }
      }
    }
    allContentfulPrint {
      edges {
        node {
          id
          image {
            file {
              url
              fileName
            }
          }
          name
          reference {
              id
              image {
                  file {
                      url
                      fileName
                  }
              }
              name
              description {
                  description
              }
              price
              year
          }
          description {
            description
          }
          price
          year
        }
      }
    }
  }
`

class ImagesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      category: "paintings",
      year: "Välj år",
      printData: null,
      paintingData: null,
    }
  }

  resolvePairs(data) {
    let resolvedIDS = []
    let resolvedNodes = []

    data.edges.map(entry => {

      if (entry.node.reference) {

        if (resolvedIDS.indexOf(entry.node.id) === -1) {
          resolvedIDS.push(entry.node.id)
          resolvedNodes.push({ node: entry.node })

          if (entry.node.reference) {
            if (resolvedIDS.indexOf(entry.node.reference.id) === -1) {
              resolvedIDS.push(entry.node.reference.id)
              resolvedNodes.push({ node: entry.node.reference })
            }
          }
        }
      }
    })

    data.edges.map(entry => {
      if (resolvedIDS.indexOf(entry.node.id) === -1) {
        resolvedIDS.push(entry.node.id)
        resolvedNodes.push({ node: entry.node })

        if (entry.node.reference) {
          if (resolvedIDS.indexOf(entry.node.reference.id) === -1) {
            resolvedIDS.push(entry.node.reference.id)
            resolvedNodes.push({ node: entry.node.reference })
          }
        }
      }
    })

    return {edges: resolvedNodes}
  }

  createChunks(data, size) {
    const filteredEdges = this.filterImagesByYear(data.edges)
    return filteredEdges.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / size)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  getAvailableYears = data => {
    let uniqueYears = []
    data.edges.map(edge => {
      const year = new Date(edge.node.year).getFullYear().toString()

      if (uniqueYears.indexOf(year) === -1) {
        uniqueYears.push(year)
      }
    })

    uniqueYears.push("Välj år")

    return uniqueYears
  }

  filterImagesByYear = edges => {
    return edges.filter(edge => {
      const year = new Date(edge.node.year).getFullYear().toString()
      if (this.state.year === "Välj år") {
        return true
      }

      return this.state.year === year ? true : false
    })
  }

  changeCategory = value => {
    this.setState({ category: value, year: "Välj år" })
  }

  handleChange = e => {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change)
  }

  render() {
    return (
      <StaticQuery
        query={imageQuery}
        render={data => {
          const images = this.createChunks(
            this.state.category === "paintings"
              ? data.allContentfulPainting
              : this.resolvePairs(data.allContentfulPrint),
            this.state.category === "paintings" ? 3 : 2
          )

          return (
            <Layout>
              <SEO
                title="Bilder"
                keywords={[`Målningar`, `Grafik`, `Utställningar`, `År`]}
              />
              <div className={"row"}>
                <div className={"twelve columns"}>
                  <h4 style={{ fontWeight: `900` }}>
                    {this.state.category === "paintings" ? "MÅLERI" : "GRAFIK"}
                  </h4>
                </div>
              </div>
              <div className={"row"} style={{ marginBottom: "-10px" }}>
                <div className={"four columns"}>
                  <select
                    style={{ height: "3.0em" }}
                    className="u-full-width"
                    name="year"
                    value={this.state.year}
                    onChange={this.handleChange}
                  >
                    {this.getAvailableYears(
                      this.state.category === "paintings"
                        ? data.allContentfulPainting
                        : data.allContentfulPrint
                    ).map(value => {
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div
                  className={"eight columns"}
                  style={{ textAlign: "right", marginTop: "5px" }}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: `400`,
                      color: `#0c0c0c`,
                      marginRight: "5px",
                    }}
                    onClick={() => this.changeCategory("paintings")}
                  >
                    Måleri
                  </span>
                  {"  |  "}
                  <span
                    style={{
                      cursor: "pointer",
                      fontWeight: `400`,
                      color: `#0c0c0c`,
                      marginLeft: "5px",
                    }}
                    onClick={() => this.changeCategory("prints")}
                  >
                    Grafik
                  </span>
                </div>
              </div>
              <hr />

              {images.map((nodes, index) => {
                return (
                  <div key={index} className={"row"}>
                    {nodes.map((node, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            this.state.category === "paintings"
                              ? "four columns"
                              : "six columns"
                          }
                        >
                          <Link
                            to={
                              (this.state.category === "paintings" ? "/painting/" : "/print/") +
                              node.node.name
                                .toLowerCase()
                                .replace(/[, ]+/g, " ")
                                .trim()
                                .replace(/(^\s+|[^a-zA-Z0-9 ]+|\s+$)/g, "")
                                .replace(/ /g, "-")
                            }
                          >
                            <img
                              style={{ width: "100%" }}
                              src={node.node.image.file.url}
                              alt={node.node.image.file.fileName}
                            />
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Layout>
          )
        }}
      />
    )
  }
}

export default ImagesPage
